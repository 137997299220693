import React from "react";
import "./index.scss";
import { HomeHeader, HomeFooter } from "@/view/components/index";

import { Link } from "react-router-dom";
import banner from "../../assets/images/activity/bg.png";
import title from "../../assets/images/activity/title.svg";
import title_1 from "../../assets/images/activity/title_1.svg";
import title_3 from "../../assets/images/activity/title_2.svg";
import title_2 from "../../assets/images/activity/title_3.svg";
import box1_1_register from "../../assets/images/activity/box1_1_register.png";
import box1_1 from "../../assets/images/activity/box1_1.png";
import box1_2_new from "../../assets/images/activity/box1_2_new.png";

import box1_3 from "../../assets/images/activity/box1_3.png";
import box2_1_new from "../../assets/images/activity/box2_1_new.png";
import box2_2 from "../../assets/images/activity/box2_2.png";
// import box3_1_new from "../../assets/images/activity/box3_1_new.png";
import box3_2_new from "../../assets/images/activity/box3_2_new.png";
import Store from "@/store";
import { observer } from "mobx-react-lite";

function Activity() {
  const { isLogin } = Store.User;

  return (
    <div id={"Activity"}>
      <HomeHeader />
      <div className={"banner_box"}>
        <img src={title} alt="" className={"title"} />
        <img src={banner} alt="" className={"bg"} />
      </div>
      <main>
        <div>
          <img src={title_1} alt="" />
          <div>
            <Link to={!isLogin && "/CommonRegister"}>
              <img src={isLogin ? box1_1 : box1_1_register} alt="" />
            </Link>
            <Link
              to={isLogin ? `/account/nameVerify` : "/CommonLogin"}
              onClick={() => {
                Store.Quote.switchSimulate(false);
              }}
            >
              <img src={box1_2_new} alt="" />
            </Link>
            <Link
              to={isLogin ? `/trade/CL` : "/CommonLogin"}
              onClick={() => {
                Store.Quote.switchSimulate(true);
              }}
            >
              <img src={box1_3} alt="" />
            </Link>
          </div>
        </div>
        <div>
          <img src={title_2} alt="" />
          <div>
            <Link
              to={isLogin ? "/trade/CL" : "/CommonLogin"}
              onClick={() => {
                Store.Quote.switchSimulate(false);
              }}
            >
              <img src={box2_1_new} alt="" />
            </Link>
            <Link
              to={isLogin ? "/trade/CL" : "/CommonLogin"}
              onClick={() => {
                Store.Quote.switchSimulate(false);
              }}
            >
              <img src={box2_2} alt="" />
            </Link>
          </div>
        </div>
        <div>
          <img src={title_3} alt="" />
          <div>
            {/* <Link
              to={{ pathname: isLogin ? "/account/recharge" : "/CommonLogin" }}
              state={2}
              onClick={() => {
                Store.Quote.switchSimulate(true);
              }}
            >
              <img src={box3_1_new} alt="" />
            </Link> */}
            <Link
              to={{ pathname: isLogin ? "/account/recharge" : "/CommonLogin" }}
              state={1}
              onClick={() => {
                Store.Quote.switchSimulate(false);
              }}
            >
              <img src={box3_2_new} alt="" />
            </Link>
          </div>
        </div>
      </main>
      <HomeFooter />
    </div>
  );
}

export default observer(Activity);
